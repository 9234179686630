import React from 'react';
import { Grid, Container, Image, Button, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import LanguageSwitch from '../../languageSwitch/languageSwitch';

const Start = (props) => {
	const { t, i18n } = useTranslation();

 	const startForm = (e) => {
		e.preventDefault();
		props.nextStep();
	}
	
	return (
	<React.Fragment>
		<Grid>
			<Grid.Row textAlign='center'>
				<Grid.Column>
					<LanguageSwitch />
				</Grid.Column>
			</Grid.Row>
			<Grid.Row columns={2}>
				<Grid.Column>
					<Image src='https://via.placeholder.com/400x400?text=Placeholder+Image' />
				</Grid.Column>
				<Grid.Column>
					<p style={{ textAlign:'left' }}>{t('welcome')}</p>
					<Button fluid primary animated onClick={startForm}>
						<Button.Content visible> {t('begin application')} </Button.Content>
						<Button.Content hidden> <Icon name="arrow right" /> </Button.Content>
					</Button>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	</React.Fragment>
	);
}

export default Start;
