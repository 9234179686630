import React from 'react';
import { Grid, Header, Form, Button, Icon, TextArea } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const Additional = (props) => {
	const { t, i18n } = useTranslation();	
	
	const { values } = props;
	
	const saveAndContinue = (e) => {
		e.preventDefault();
		props.nextStep();
	}

	return (
		<React.Fragment>
			<Form.Field
				control={TextArea}
				placeholder={t('additionalInfo')} 
				onChange={props.handleOnChange('additionalInfo')}
				defaultValue={values.additionalInfo}
			/>
			<Button.Group>
				<Button animated primary onClick={props.prevStep}>
					<Button.Content visible>{t('back')}</Button.Content>
					<Button.Content hidden><Icon name='arrow left' /></Button.Content>
				</Button>
				<Button animated primary onClick={saveAndContinue}>
					<Button.Content visible>{t('next')}</Button.Content>
					<Button.Content hidden><Icon name='arrow right' /></Button.Content>
				</Button> 
			</Button.Group>
		</React.Fragment>
 )
}

export default Additional; 
