import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from 'js-yaml-loader!./locales/en/translation.yaml';
import translationDE from 'js-yaml-loader!./locales/de/translation.yaml';
import translationUA from 'js-yaml-loader!./locales/ua/translation.yaml';

const resources = {
	en: {
		translation: translationEN
	},
	de: {
		translation: translationDE
	},
	ua: {
		translation: translationUA
	}
}

i18n
	.use(initReactI18next)
	.init({
		resources,
		lng: "ua",
		keySeparator: false,
		interpolation: {
			escapeValue: false
		}
});

export default i18n;
