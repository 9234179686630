import React from 'react';
import { Grid, Header, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';	

const Success = () => {
	const { t, i18n } = useTranslation();
	return(
		<Grid style={{ height:'100vh' }} verticalAlign='middle'>
			<Grid.Row>
				<Grid.Column>
					<Header as='h1' icon textAlign='center'>
						<Icon name='handshake outline' circular />
						<Header.Content>{t('Thank You!')}</Header.Content>
					</Header>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	)
}

export default Success;
