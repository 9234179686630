import React from 'react';
import { Button, Flag } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';


const LanguageSwitch = () => {
	const { t, i18n } = useTranslation();
	
	const changeLanguageHandler = (languageValue) => {
		console.log(languageValue);
		i18n.changeLanguage(languageValue);
	}

	return(
		<React.Fragment>
			<Button.Group compact>
				<Button basic color={i18n.language === 'en' ? 'yellow' : 'blue'} onClick={ () => changeLanguageHandler('en') }>
					<Button.Content>
						<Flag name="gb" />
						{t('english')}
					</Button.Content>
				</Button>
				<Button basic color={i18n.language === 'de' ? 'yellow' : 'blue'} onClick={ () => changeLanguageHandler('de') }>
					<Button.Content>
						<Flag name="de" />
						{t('german')}
					</Button.Content>
				</Button>
				<Button basic color={i18n.language === 'ua' ? 'yellow' : 'blue'} onClick={ () => changeLanguageHandler('ua') }>
					<Button.Content>
						<Flag name="ua" />
						{t('ukrainian')}
					</Button.Content>
				</Button>
			</Button.Group>
		</React.Fragment>
	);
} 

export default LanguageSwitch;
