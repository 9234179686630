import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Step } from 'semantic-ui-react';

const Steps = (props) => {	
	const step  = props.state.step;
	const { t, i18n } = useTranslation();	

	return(
		<React.Fragment>
			<Step.Group size='mini' widths={6} stackable='tablet' style={{ marginTop:'1em' }}>
				<Step active={step === 2}>
					<Icon name='address card outline' />
					<Step.Title>{t('personal data')}</Step.Title>
				</Step>
				<Step active={step === 3}>
					<Icon name='clipboard outline' />
					<Step.Title>{t('project data')}</Step.Title>
				</Step>
				<Step active={step === 4}>
					<Icon name='question circle outline' />
					<Step.Title>{t('additional info')}</Step.Title>
				</Step>	
				<Step active={step === 5}>
					<Icon name='save outline' />
					<Step.Title>{t('verify data')}</Step.Title>
				</Step>	
				<Step active={step === 6}>
					<Icon name='handshake outline' />
					<Step.Title>{t('thanks!')}</Step.Title>
				</Step>	
			</Step.Group>
		</React.Fragment>		
	);
}

export default Steps;
