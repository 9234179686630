import React, { Component } from 'react';
import { Container, Form, Grid } from 'semantic-ui-react';

import Start from './steps/start';
import Person from './steps/person';
import Project from './steps/project';
import Additional from './steps/additional';
import Confirm from './steps/confirm';
import Success from './steps/success';

import './form.css';

class FormContainer extends Component {
	render() {
		const { step } = this.props.state;
		const { firstName, lastName, email, age, city, country, projectName, projectDescription, additionalNotes, confirmData } = this.props.state;
		const values = { firstName, lastName, email, age, city, country, projectName, projectDescription, additionalNotes, confirmData };
  		return (
					<Grid style={{  height:'85vh' }} verticalAlign='middle'>
						<Grid.Row>
							<Grid.Column>
								<Form>
									<FormStep 
										values={values} 
										prevStep={this.props.prevStep} 
										nextStep={this.props.nextStep} 
										handleOnChange={this.props.handleOnChange}
										saveData={this.props.saveData}
										step={step}
									/> 
								</Form>
							</Grid.Column>
						</Grid.Row>
					</Grid>
			)	
	}
}	 

const FormStep = ({ values, prevStep, nextStep, handleOnChange, saveData, step }) => {
	switch(step) {	
		case 1:
			return <Start 
							nextStep={ nextStep }
							handleOnChange={ () => handleOnChange(e) }
							values={values}			
						 />;
		case 2:
			return <Person 
							nextStep={ nextStep }
							prevStep={ prevStep }
							handleOnChange={ (e) => handleOnChange(e) }
							values={values}
						 />;
		case 3:
			return <Project 
							nextStep={nextStep}
							prevStep={prevStep}
							handleOnChange={ (e) => handleOnChange(e) }
							values={values}
						 />;
		case 4:
			return <Additional
							nextStep={nextStep}
							prevStep={prevStep}
							handleOnChange={ (e) => handleOnChange(e) }
							values={values}
						 />;
		case 5:
			return <Confirm
							nextStep={nextStep}
							prevStep={prevStep}
							handleOnChange={ (e) => handleOnChange(e)}
							saveData={ () => saveData() }
							values={values}
				 		/>;
		case 6:
			return <Success />;
	}
}


export default FormContainer;
