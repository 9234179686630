import React, { useEffect } from 'react';
import { Grid, Header, Form, Input, Button, Icon, TextArea } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const Project = (props) => {
	const { t, i18n } = useTranslation();	
	
	const { values } = props;
	
	const { 
		register,
		setValue,
		handleSubmit,
		formState: { errors },
		trigger
  } = useForm({ mode: 'onBlur' })

	const saveAndContinue = (e) => {
		e.preventDefault();
		props.nextStep();
	}

	const handleOnBlur = (e) => {
		e.persist();
		setValue(e.target.name, e.target.value);
		trigger(e.target.name);
	}

	useEffect(() => {
  	register('projectName', { required: true });
		register('projectDescription', { required: true });
	}, [register]);

	return (
		<React.Fragment>
			<Form.Field
				name='projectName'
				control={Input}
				placeholder={t('project name')}
				onBlur={handleOnBlur} 
				onChange={props.handleOnChange('projectName')}
				defaultValue={values.projectName}
				error={ errors.projectName ? true : false }
			/>
			<Form.Field
				name='projectDescription'
				control={TextArea}
				placeholder={t('projectDescription')} 
				onChange={props.handleOnChange('projectDescription')}
				onBlur={handleOnBlur}
				defaultValue={values.projectDescription}
				error={ errors.projectDescription ? true : false }
			/>
			<Button.Group>
				<Button animated primary onClick={props.prevStep}>
					<Button.Content visible>{t('back')}</Button.Content>
					<Button.Content hidden><Icon name='arrow left' /></Button.Content>
				</Button>
				<Button animated primary onClick={saveAndContinue}>
					<Button.Content visible>{t('next')}</Button.Content>
					<Button.Content hidden><Icon name='arrow right' /></Button.Content>
				</Button> 
			</Button.Group>
		</React.Fragment>
 )
}

export default Project; 
