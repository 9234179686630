import React, { useEffect } from 'react';
import { Grid, Form, Input, Button, Icon} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';

const Person = (props) => {
	const { t, i18n } = useTranslation();	

	const { 
		register, 
		formState: { errors }, 
		setValue,
		handleSubmit,
		trigger 
	} = useForm({mode: 'onBlur'});	
	
	const { values } = props;

	useEffect(() => {
		register('firstName', { required: true });
		register('lastName', { required: true });
		register('age');
		register('email', { required: true });
	}, [register]);
	
	const saveAndContinue = (data, e) => {
		e.preventDefault();		
		props.nextStep();
	}

	const handleOnBlur = (e) =>{
		e.persist();
		setValue(e.target.name, e.target.value);
		trigger(e.target.name);
	}	

	return (
		<React.Fragment>
						<Form.Group widths='equal'>
							<Form.Field
								control={Input}
								placeholder={t('firstName')} 
								onChange={props.handleOnChange('firstName')}
								onBlur={handleOnBlur}
								defaultValue={values.firstName}
								name='firstName'
								error={ errors.firstName ? true : false }
							/>
							<Form.Field
								control={Input}
								placeholder={t('lastName')} 
								onChange={props.handleOnChange('lastName')}
								onBlur={handleOnBlur}
								defaultValue={values.lastName}
								name='lastName'
								error={ errors.lastName ? true : false }
							/>
						</Form.Group>
						<Form.Group widths='equal'>
							<Form.Field
								control={Input}
								placeholder={t('age')} 
								onChange={props.handleOnChange('age')}
								onBlur={handleOnBlur}
								defaultValue={values.age}
								name='age'
								error={ errors.age ? true : false }
							/>
							<Form.Field
								control={Input}
								placeholder={t('email')}
								onChange={props.handleOnChange('email')}
								onBlur={handleOnBlur}
								defaultValue={values.email}
								name='email'
								error={ errors.email ? true : false }
							/>
						</Form.Group>
						<Form.Group widths='equal'>
							<Form.Field
								control={Input}
								placeholder={t('city')}
								onChange={props.handleOnChange('city')} 
								onBlur={handleOnBlur}
								defaultValue={values.city}
								error={ errors.city ? true : false }
							/>
							<Form.Field
								control={Input}
								placeholder={t('country')} 
								onChange={props.handleOnChange('country')}
								onBlur={handleOnBlur}
								defaultValue={values.country}	
								error={ errors.country ? true : false }
							/>
						</Form.Group>	
						<Button.Group>
							<Button animated primary onClick={props.prevStep}>
								<Button.Content visible>{t('back')}</Button.Content>
								<Button.Content hidden><Icon name='arrow left' /></Button.Content>
							</Button>
							<Button animated primary onClick={handleSubmit(saveAndContinue)}>
								<Button.Content visible>{t('next')}</Button.Content>
								<Button.Content hidden><Icon name='arrow right' /></Button.Content>
							</Button> 
						</Button.Group>
		</React.Fragment>
 )
}

export default Person; 
