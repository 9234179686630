import React, { Component } from 'react';
import { Container, Grid } from 'semantic-ui-react';

import FormContainer from './form/form';
import Steps from './steps/steps';

class App extends Component {  
	
	state = {
		step: 1,
		firstName: '',
		lastName: '',
		age: '',
		email: '',
		city: '',
		country: '',
		projectName: '',
		projectDescription: '',
		additionalInfo: ''
	}

  prevStep = () => {
		const { step } = this.state;
		this.setState({
				step: step - 1
		});
	}

	nextStep = () => {
		const { step } = this.state;
		this.setState({
			step: step + 1
		});
	}
  
	saveData = () => {
		console.log(this.state);
	}	

	handleOnChange = input => e => {
		this.setState((prevState) => {
			return {
				...prevState,
				[input]: e.target.value
			}
		});
	}

	render() {
		return (	
			<div id="app">
				<Container textAlign='center' style={{ height:'100vh' }}>
					<Steps state={this.state} />
					<FormContainer 
						state={this.state} 
						nextStep={this.nextStep} 
						prevStep={this.prevStep} 
						saveData={this.saveData}
						handleOnChange={this.handleOnChange}
					/>
				</Container>
			</div>
		);
	} 
}

export default App;
