import React from 'react';
import { Grid, Table, Button, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const Confirm = (props) => {
	const { values } = props;
	const { t, i18n } = useTranslation();
	
	const saveAndContinue = (e) => {
		e.preventDefault();
		props.nextStep();
	}	
	
	
	return(
		<React.Fragment>
			<Table striped>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell colspan={2}>{t('Please verify your data')}</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				{ Object.keys(values).map((key,index) => {
					return(
						<Table.Row>
							<Table.Cell>{t(key)}</Table.Cell>
							<Table.Cell>{values[key]}</Table.Cell>
						</Table.Row>
					);
				}) }
			</Table>
			<Button.Group>
    		<Button animated primary onClick={props.prevStep}>
	    		<Button.Content visible>{t('back')}</Button.Content>
					<Button.Content hidden><Icon name='arrow left' /></Button.Content>
				</Button>
 				<Button animated primary onClick={saveAndContinue}>
					<Button.Content visible>{t('next')}</Button.Content>
					<Button.Content hidden><Icon name='arrow right' /></Button.Content>
				</Button>
			</Button.Group>
		</React.Fragment>
	);
}

export default Confirm;
